<template>
  <gf-container>
    <gf-form>
      <el-form label-width="170px" :model="variant" ref="variant" :rules="variantRule">
        <el-form-item>
          <gf-alert>
            <template #icon>
              <span class="svg-icon svg-icon-primary svg-icon-md">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "/>
                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Provide details of this variant below.
          </gf-alert>
        </el-form-item>
        <el-form-item label="Variant Name" size="medium" prop="name">
          <el-input v-model="variant.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="SKU" size="medium">
          <el-input v-model="variant.sku" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="variant.options.length > 0" class="mb-2">
          <span class="font-weight-bold text-dark-75">Variant Option</span>
        </el-form-item>
        <el-form-item v-for="(option, index) in variant.options" :key="option.id" :label="variant.options_label[index].name" :prop="`options.${index}.value`" :rules="{required: true, message: 'Option Name is require'}">
          <el-input v-model="option.value" @input="generateSKU"></el-input>
        </el-form-item>
        <el-form-item v-if="variant.prices.length > 0" class="mb-2">
          <span class="font-weight-bold text-dark-75">Prices</span>
        </el-form-item>
        <el-form-item :label="priceLabel[index]" v-for="(price, index) in variant.prices" :key="price.id">
          <el-input v-model="price.value"><template slot="prepend">₱</template></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <gf-button type="primary" @click="save">Save Changes</gf-button>
      </template>
    </gf-form>
    <template #aside>
      <div class="mb-3 text-center">
        <span class="font-size-lg font-weight-bold text-muted">Overview</span>
      </div>
      <div class="item mb-2">
        <span class="item__title font-size-sm font-weight-normal text-muted">Available Stocks </span>
        <span class="item__value font-size-sm font-weight-normal">{{ $NumberFormat(variant.stocks[0].value) }}</span>
      </div>
      <div class="item mb-2 ">
        <span class="item__title font-size-sm font-weight-normal text-muted">On Hold Stocks </span>
        <span class="item__value font-size-sm font-weight-normal">{{ $NumberFormat(variant.stocks[0].value - variant.stocks[0].commited) }}</span>
      </div>
      <div class="item mb-2 ">
        <span class="item__title font-size-sm font-weight-normal text-muted">Commited Stocks </span>
        <span class="item__value font-size-sm font-weight-normal">{{ $NumberFormat(variant.stocks[0].commited) }}</span>
      </div>
    </template>
  </gf-container>
</template>

<style lang="scss" scoped>
.item {
  display: flex;

  &__title {
    flex-grow: 1;
  }

  &__title, &__value {
    border-bottom: 1px dotted #333;
  }
}
</style>

<script>
// services
import ProductVariantService from '@/services/v1/ProductVariant'

export default {
  data () {
    return {
      // ui
      loading: false,

      // main
      variant: {
        name: null,
        sku: null,
        options: [],
        optionLabels: [],
        prices: [],
        stocks: [{
          value: 0.00,
          commited: 0.00
        }]
      },

      priceLabel: ['Buy Price', 'Wholesale Price', 'Retail Price'],

      // Rule
      variantRule: {
        name: [
          { required: true, message: 'Variant name can\'t be blank', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // handler
    save () {
      this.$refs.variant.validate()
        .then(async () => {
          try {
            this.loading = true
            const variant = this.$_.cloneDeep(this.variant)

            delete variant.options_label
            delete variant.product
            delete variant.stocks

            if (variant.options.length === 0) delete variant.options
            if (variant.prices.length === 0) delete variant.prices

            this.$Sanitize(variant)

            const pvService = new ProductVariantService(this.$route.params.id, this.$route.params.vid)
            await pvService.update(variant)
            this.$message.success(`${this.variant.name} successfully updated`)

            this.getVariant()
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    generateSKU () {
      this.variant.name = this.variant.options.map(s => s.value).join(':')
      this.variant.sku = this.variant.product.sku + '-' + this.variant.options.map(s => s.value.substring(0, 1)).join('-')
    },

    // services fetch
    async getVariant () {
      try {
        this.loading = true
        const pvService = new ProductVariantService(this.$route.params.id, this.$route.params.vid)
        const response = await pvService.get()
        this.variant = response.data

        if (this.variant.prices.length === 0) {
          this.variant.prices = [
            {
              price_list_id: 1,
              value: 0
            },
            {
              price_list_id: 2,
              value: 0
            },
            {
              price_list_id: 3,
              value: 0
            }
          ]
        }
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 1)
    await this.getVariant()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Products' },
      { title: this.variant.product.name },
      { title: 'Variants' },
      { title: this.variant.name }
    ])
  }
}
</script>
